<template>
	<div
		class="loader"
		:style="{
			borderLeftColor: borderColor,
			borderWidth: `${borderWidth}px`,
			height: `${size}px`,
			width: `${size}px`,
			position: position,
			left: position === 'absolute' && center ? `calc(50% - ${size / 2}px)` : null,
		}"
	></div>
</template>

<script>
export default {
	props: {
		size: {
			default: 30,
			type: Number,
		},

		borderWidth: {
			default: 4,
			type: Number,
		},

		borderColor: {
			type: String,
		},

		position: {
			type: String,
			default: 'static',
		},

		center: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.loader {
	animation: donut-spin 1.2s linear infinite;
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: $primary-color;
	border-radius: 50%;
	display: inline-block;
	margin: 0 auto;
}

@keyframes donut-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>

<style lang="scss">
.loader-container {
	&.fullscreen {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		padding-bottom: 25vh;
		width: 100%;
	}
}
</style>
