var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader",style:({
		borderLeftColor: _vm.borderColor,
		borderWidth: `${_vm.borderWidth}px`,
		height: `${_vm.size}px`,
		width: `${_vm.size}px`,
		position: _vm.position,
		left: _vm.position === 'absolute' && _vm.center ? `calc(50% - ${_vm.size / 2}px)` : null,
	})})
}
var staticRenderFns = []

export { render, staticRenderFns }